<template>
    <div :class="{'ly-is-full':isFull}">
      <el-form :inline="true" :model="formInline" label-position="left">

      <div class="tableSelect" ref="tableSelect">
                 <el-form-item label="交易对：">
                    <el-select v-model="formInline.symbol" placeholder="请选择" clearable @change="handleSelectChange" style="width:130px">
                        <el-option
                                v-for="item in symbolList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="回测时间：">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="YYYY-MM-DD HH:mm"
                            value-format="YYYY-MM-DD HH:mm"
                            :shortcuts="shortcuts">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label=""><el-button  @click="search()" type="primary" icon="Search" >查询</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleReset()" icon="Refresh">重置</el-button></el-form-item>

      </div>
      <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>


      <div class="tableSelect" ref="tableSelect">
                <el-form-item label="极值间隔：">
                    <el-input  v-model.number="formInline.pre_scope" type="number" maxlength="60" clearable placeholder="30(30分钟高低点)"   style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="持仓时长：">
                    <el-input  v-model.number="formInline.post_scope" type="number" maxlength="60" clearable placeholder="30(持仓30分钟)"  style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="利润空间：">
                    <el-input  v-model.number="formInline.profit_scope" type="number" maxlength="60" clearable placeholder="20(20美元的利润)"  style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="止损空间：">
                    <el-input  v-model.number="formInline.stop_loss_scope" type="number" maxlength="60" clearable placeholder="60(交易价格设置反向60美元止损)"  style="width:200px"></el-input>
                </el-form-item>
       </div>
       </el-form>

        <ul class="backtest-static" >
            <li>{{dataResult.dataCheckResult}} </li>
            <li>{{dataResult.dataResultSellsSummary}}</li>
            <li>{{dataResult.dataResultBuysSummary}}</li>
        </ul>


     <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
         <el-tab-pane label="卖出信号" name="first">
            <el-table max-height="320px"  border :data="sellsPage" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="index" width="50" />
                <el-table-column min-width="160" prop="openTime" label="卖出信号时间"></el-table-column>
                <el-table-column min-width="160" prop="openPrice" label="理论卖出价格"></el-table-column>
                <el-table-column min-width="160" prop="closeTime" label="平仓时间"></el-table-column>
                <el-table-column min-width="160" prop="closePrice" label="平仓价格"></el-table-column>
                <el-table-column min-width="160" prop="stopLossTime" label="止损时间"></el-table-column>
                <el-table-column min-width="160" prop="stopLossPrice" label="止损价格"></el-table-column>
            </el-table>
            <el-pagination :current-page="page" :page-size="pageSize"  :total="sells.length"  layout="prev, pager, next" @current-change="changeSellsPage" hide-on-single-page=true   />

         </el-tab-pane>

        <el-tab-pane label="买入信号" name="second">
            <el-table max-height="320px" border :data="buysPage" ref="tableref" v-loading="loadingPage" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="index" width="50" />
                <el-table-column min-width="160" prop="openTime" label="买入信号时间"></el-table-column>
                <el-table-column min-width="160" prop="openPrice" label="理论买入价格"></el-table-column>
                <el-table-column min-width="160" prop="closeTime" label="平仓时间"></el-table-column>
                <el-table-column min-width="160" prop="closePrice" label="平仓价格"></el-table-column>
                <el-table-column min-width="160" prop="stopLossTime" label="止损时间"></el-table-column>
                <el-table-column min-width="160" prop="stopLossPrice" label="止损价格"></el-table-column>
            </el-table>
           <el-pagination  :current-page="page"  :total="buys.length"  layout="prev, pager, next" @current-change="changeBuysPage" hide-on-single-page=true />
        </el-tab-pane>
    </el-tabs>
    </div>
</template>
<script>
    import {dateFormats} from "@/utils/util";
    import { lyMixins } from "@/mixins/mixins";
    import {backtestCheckData,doTurtleBacktest} from '@/api/api';
    import Pagination from "@/components/Pagination";

    export default {
        mixins: [lyMixins],
        components:{
        },
        name:'backtest',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                defaultImg:"this.src='"+require('../../assets/img/avatar.jpg')+"'",
                pageSize:10,
                formInline:{
                        symbol:'',
                        pre_scope: '',
                        post_scope: '',
                        profit_scope: '',
                        stop_loss_scope: ''
                },
                timers:[],
                dataResult:{
                    dataCheckResult:'',
                    dataResultSellsSummary:'',
                    dataResultBuysSummary: ''
                },
                symbolList:[
                    {id:'BTCTUSD',name:'BTCTUSD'},
                    {id:'ETHTUSD',name:'ETHTUSD'},
                    {id:'BNBTUSD',name:'BNBTUSD'},
                    {id:'BTCUSDT',name:'BTCUSDT'},
                    {id:'ETHUSDT',name:'ETHUSDT'},
                    {id:'BNBUSDT',name:'BNBUSDT'}
                ],
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                shortcuts: [
                    {
                        text: '今日',
                        value:()=>{
                            var end = new Date();
                            var start = new Date();
                            start.setHours(0, 0, 0, 0);
                            return [start, end];
                        }
                    },
                    {
                        text: '最近一周',
                        value:()=>{
                            var end = new Date();
                            var start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [dateFormats(start,'yyyy-MM-dd hh:mm'), dateFormats(end,'yyyy-MM-dd hh:mm')];
                        }
                    },
                    {
                        text: '最近一个月',
                        value:()=> {
                            var end = new Date();
                            var start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [dateFormats(start,'yyyy-MM-dd hh:mm'), dateFormats(end,'yyyy-MM-dd hh:mm')];
                         }
                    }
                 ]
             }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            //获取列表
            async checkData() {
                 backtestCheckData(this.formInline).then(res => {
                     if(res.code ==2000) {
                           this.dataResult.dataCheckResult =  res.data.data;
                     }
                 });
            },
            validateForm(){
                var data = {"msg":'',"flag":false};

                if (this.formInline.beginAt == null){
                        data.msg = "请设置开始时间！";
                        return data;
                }
                if(this.formInline.endAt == null){
                        data.msg = "请设置结束时间！";
                        return data;
                }
                if(!Number.isInteger(this.formInline.pre_scope) ||this.formInline.pre_scope <=0){
                        data.msg = "极值间隔必须为大于0的整数！";
                        return data;
                }
                if(!Number.isInteger(this.formInline.post_scope) ||this.formInline.post_scope <=0){
                        data.msg = "持仓时长必须为大于0的整数！";
                        return data;
                }
                if(!Number.isInteger(this.formInline.profit_scope) ||this.formInline.profit_scope <=0){
                        data.msg = "利润空间必须为大于0的整数！";
                        return data;
                }
                if(!Number.isInteger(this.formInline.stop_loss_scope) ||this.formInline.stop_loss_scope <=0){
                        data.msg = "止损空间必须为大于0的整数！";
                        return data;
                }
                data.flag = true;
                return data;
            },
            search(){
                 var ret = this.validateForm();
                 if (!ret.flag){
                    alert(ret.msg);
                    return;
                 }
                 this.loadingPage = true;
                 doTurtleBacktest(this.formInline).then(res => {
                     if(res.code ==2000) {
                           this.loadingPage = false;
                           var data = res.data.data;
                           this.dataResult.dataCheckResult = "数据集校验结果:" +  data.dataCheckResult;
                           this.dataResult.dataResultSellsSummary = data.dataResultSummary.sells_msg ;
                           this.dataResult.dataResultBuysSummary =  data.dataResultSummary.buys_msg;
                           if(data.sells != null) {
                                this.sells = data.sells;
                                this.sellsPage = this.getPageData(this.sells,1,this.pageSize);
                           }
                           if(data.buys != null){
                                this.buys = data.buys;
                                this.buysPage = this.getPageData(this.buys,1,this.pageSize);
                           }

                     }
                 });
            },
            changeSellsPage(pageNo){
                this.loadingPage = true;
                this.sellsPage = this.getPageData(this.sells,pageNo,this.pageSize);
                this.loadingPage = false;
            },
            changeBuysPage(pageNo){
                this.loadingPage = true;
                this.buysPage = this.getPageData(this.buys,pageNo,this.pageSize);
                this.loadingPage = false;
            },
            getPageData(dlist,pageNo,pageSize){
                var data = [];
                var lens = dlist.length;
                if(lens >0) {
                     data = dlist.slice((pageNo - 1) * pageSize, pageNo * pageSize);
                }
                return data;
            },
            timeChange(val){
                if (val) {
                    //alert(val[0]);
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
            },
            handleReset(){
                this.initForm();
                this.activeName = "first";
                //this.checkData();
            },
            initForm(){
                    this.formInline = {
                        symbol:'BTCTUSD',
                        pre_scope: 30,
                        post_scope: 30,
                        profit_scope: 20,
                        stop_loss_scope: 60
                    }
                    this.timers = [];
                    this.sells = [];
                    this.buys = [];
                    this.buysPage = [];
                    this.sellsPage = [];
                     var start = new Date();
                     var end = new Date();
                     start.setHours(0,0,0,0);
                     this.timers[0] = dateFormats(start,'yyyy-MM-dd hh:mm');
                     this.timers[1] = dateFormats(end,'yyyy-MM-dd hh:mm');
                     this.formInline.beginAt= this.timers[0];
                     this.formInline.endAt= this.timers[1];

            },
            handleSelectChange(value){
            }
        },
        created() {
            this.initForm();
            this.activeName = "first";
            //this.checkData();
        }
    }

</script>

<style lang="scss">
    .backtest-static{
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-bottom:5px;
        }
    }
</style>
